define("ember-bootstrap/templates/components/bs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3IR7Tdxd",
    "block": "{\"symbols\":[\"Element\",\"@title\",\"&default\",\"@class\",\"&attrs\"],\"statements\":[[1,[30,[36,5],[[35,4]],null]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"inDom\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"elementComponent\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[[17,5]],[[\"@placement\",\"@fade\",\"@showHelp\",\"@renderInPlace\",\"@destinationElement\",\"@popperTarget\",\"@autoPlacement\",\"@viewportElement\",\"@viewportPadding\",\"@id\",\"@class\"],[[32,0,[\"placement\"]],[32,0,[\"fade\"]],[32,0,[\"showHelp\"]],[32,0,[\"_renderInPlace\"]],[32,0,[\"destinationElement\"]],[32,0,[\"triggerTargetElement\"]],[32,0,[\"autoPlacement\"]],[32,0,[\"viewportElement\"]],[32,0,[\"viewportPadding\"]],[32,0,[\"overlayId\"]],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,3,[[30,[36,0],null,[[\"close\"],[[32,0,[\"close\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\",\"component\",\"let\",\"_parentFinder\",\"unbound\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-tooltip.hbs"
    }
  });

  _exports.default = _default;
});