define("ember-pusher/index", ["exports", "ember-pusher/mixins/bindings", "ember-pusher/mixins/client-events"], function (_exports, _bindings, _clientEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    Bindings: _bindings.default,
    ClientEvents: _clientEvents.default
  };
  _exports.default = _default;
});