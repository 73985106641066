define("ember-tracker/-privates/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IN_BROWSER = void 0;
  _exports.getCurrentRoute = getCurrentRoute;
  _exports.mergeOrAssign = _exports.mergeObjects = void 0;

  /**
   * Returns the route.
   * @public
   * @type {Function}
   * @return {Route}
   */
  function getCurrentRoute(context, routeName) {
    return Ember.getOwner(context).lookup("route:".concat(routeName));
  }
  /**
   * Supports <= 2.4 with merge otherwise it chooses assign.
   * @public
   * @type {Function}
   */


  var mergeObjects = Ember.assign || Ember.merge;
  /**
   * Find out if we're in fastboot.
   * @public
   * @type {Boolean}
   */

  _exports.mergeObjects = mergeObjects;
  var IN_BROWSER = !!window && !!window.document;
  /**
   * Merge or assign.
   * @public
   * @type {Function}
   */

  _exports.IN_BROWSER = IN_BROWSER;
  var mergeOrAssign = Ember.assign || Ember.merge;
  _exports.mergeOrAssign = mergeOrAssign;
});