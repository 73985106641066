define("ember-bootstrap/templates/components/bs-accordion/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xaaSPP4o",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[28,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,5],null,[[\"title\",\"body\"],[[30,[36,4],[[32,0,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[32,0,[\"collapsed\"]],[32,0,[\"disabled\"]],[30,[36,3],[[32,0],[35,2],[35,1]],null]]]],[30,[36,4],[[32,0,[\"bodyComponent\"]]],[[\"collapsed\"],[[32,0,[\"collapsed\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[32,0,[\"titleComponent\"]]],[[\"collapsed\",\"disabled\",\"onClick\"],[[32,0,[\"collapsed\"]],[32,0,[\"disabled\"]],[30,[36,3],[[32,0],[35,2],[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[32,0,[\"bodyComponent\"]]],[[\"collapsed\"],[[32,0,[\"collapsed\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"value\",\"onClick\",\"action\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-accordion/item.hbs"
    }
  });

  _exports.default = _default;
});