define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de", {
    "auth": {
      "alreadyregistered": "Hast du schon einen Account? Jetzt inloggen!",
      "backtologin": "Zurück zur Anmeldeseite",
      "enter_email": "E-Mail-Adresse",
      "error": {
        "already_registered": "Du hast dich schon bei Play registriert.",
        "email": {
          "email": "Gib eine gültige E-Mail-Adresse an.",
          "required": "Die E-Mail Adresse muss angegeben werden."
        },
        "identification": {
          "email": "Gib eine gültige E-Mail-Adresse an.",
          "required": "Die E-Mail Adresse muss angegeben werden."
        },
        "invalid_credentials": "Falsches Passwort",
        "not_registered": "Du hast dich noch nicht bei Play registriert.",
        "password": {
          "required": "Das Passwort muss ausgefüllt sein."
        },
        "password_length": "Das neue Passwort muss mindestens 6 Zeichen lang sein.",
        "passwords_no_match": "Das neue Passwort stimmt nicht mit der Bestätigung überein.",
        "retired": "Entschuldigung, anscheinend bist du kein Mitglied von Erasmus Volley mehr.",
        "successful_forgot": "Gleich fertig! Bitte schau in deiner E-Mail nach dem Bestätigungslink.",
        "successful_registration": "Gleich fertig! Bitte schau in deiner E-Mail nach dem Bestätigungslink.",
        "too_many_attempts": "Zu viele login Versuche. Versuche es bitte in {seconds} Sekunden nochmal.",
        "unknown": "Ein unbekannter Fehler ist aufgetreten.",
        "unrecognised_email": "Entschuldigung, wir erkennen diese E-Mail-Adresse nicht."
      },
      "features": ["Deine persönliche Homepage", "Mehr Details über alle Spieler", "Team Manager für Kapitäne", "Bevorstehende Geburtstage", "Pardoen Barkartenstand"],
      "firsttime": "Zum ersten Mal hier?",
      "firsttimelong": "Zum ersten Mal hier? Melde dich jetzt an!",
      "forgot": {
        "emailtitle": "E-Mail Adresse vergessen",
        "helps": ["Probiere es mit der E-Mail Adresse über die du regelmäßig Mitgliedschaftsmails bekommst.", "Frag die Sekretär/in"],
        "passwordtitle": "Passwort vergessen",
        "title": "E-Mail-Adresse/Passwort vergessen"
      },
      "forgotpassword": "Passwort vergessen?",
      "hi": "Hallo",
      "login": "Anmelden",
      "logout": "Abmelden",
      "member_required": "Du musst Erasmus Volley Mitglied sein um dich anzumelden.",
      "next": "Weiter",
      "password": "Passwort",
      "password_confirmation": "Passwort (nochmal)",
      "pleasewait": "Ein Moment bitte...",
      "signup": {
        "helps": ["Registriere dich mit der E-Mail Adresse, mit der du dich bei Erasmus Volley angemeldet hast", "Du musst ein Erasmus Volley Mitglied sein um dich anzumelden."],
        "submit": "Überprüfen",
        "title": "Registrieren"
      },
      "verify": {
        "error_detail": "Der Link scheint abgelaufen zu sein!",
        "error_title": "Uups! Irgendwas ist schief gelaufen :(",
        "message": "Danke, dass du dich bei Erasmus Volley Play angemeldet hast! Jetzt musst du nur noch ein Passwort auswählen, dann bist du fertig!",
        "submit": "Fertig",
        "title": "Hallo {name}!"
      },
      "welcome_back": "Willkommen zurück",
      "welcomeback": "Willkommen zurück!"
    },
    "calendar": {
      "title": "Kalender"
    },
    "committee": {
      "jobs": {
        "matchsecretary": "Leiter Spielbetrieb",
        "penningmeester": "Schatzmeister(in)",
        "president": "Vorsitzende(r)",
        "promotions": "Werbecheff/in",
        "secretaris": "Sekretär/in",
        "secretary": "Sekretär/in",
        "treasurer": "Schatzmeister(in)",
        "vicepresident": "Vize Präsident",
        "vicevoorzitter": "Vize Präsident",
        "voorzitter": "Vorsitzende(r)",
        "wedstrijdsecretaris": "Leiter Spielbetrieb"
      },
      "title": "Komitees"
    },
    "court": {
      "TZ": "Trainingshalle",
      "court": "Feld",
      "halls_long": {
        "1": "Halle 1 (Erdgeschoss)",
        "2": "Halle 2 (3. Etage)",
        "3": "Halle 3 (4. Etage)"
      },
      "halls_short": {
        "1": "Halle 1",
        "2": "Halle 2",
        "3": "Halle 3"
      }
    },
    "dashboard": {
      "birthday_fundraiser": {
        "disclaimer": "Spenden werden in Pardoen ausgegeben",
        "donate": "Spenden über Tikkie"
      },
      "birthdays": {
        "none": "Keine Geburtstage heute",
        "title": "Kommende Geburtstage",
        "tomorrow": "Morgen"
      },
      "birthdays_tab": "Geburtstage",
      "no_events": {
        "date": "Keine Aktivitäten heute.",
        "detail": "In der kommenden Woche sind keine Aktivitäten für deine Lieblingsteams geplant.",
        "title": "Genieße deine Freizeit!",
        "week": "Keine Aktivitäten in dieser Woche."
      },
      "thisweek_tab": "Diese Woche",
      "title": "Meine Seite",
      "upcoming": {
        "loading": "Lade Aktivitäten",
        "loading_with_date": "Lade Aktivitäten vor dem {date}",
        "loading_with_week": "Lade Aktivitäten vor Woche {week}",
        "week": "Woche"
      }
    },
    "documents": {
      "title": "Dokumente"
    },
    "hall": {
      "address": "Adresse",
      "no_matches": "In dieser Halle werden, zumindest in dieser Saison, keine Wettkämpfe mehr stattfindend.",
      "results": "Ergebnisse",
      "schedule": "Programm",
      "title": "Sporthallen"
    },
    "locale": {
      "english": "German",
      "native": "Deutsch"
    },
    "match": {
      "attendance": {
        "cannot": "Kann nicht",
        "going": "Anwesend",
        "interested": "Interessiert",
        "maybe": "Vielleicht",
        "noresponse": "Keine Antwort",
        "remove": "Aus der Liste löschen"
      },
      "away": "Auswärtsspiele",
      "counter": "Zähler",
      "court_unknown": "Feld noch nicht bekannt",
      "date": "Datum",
      "datetime": "Datum und Uhrzeit",
      "details": "Details",
      "directions": "Wegbeschreibung",
      "home": "Heimspiele",
      "last_results": "Die zehn neuesten Ergebnisse",
      "location": "Ort",
      "more_matches": "Mehr Spiele",
      "official_actions": {
        "count": "Zähler bei",
        "line": "Linienrichter bei",
        "referee": "Schiri bei"
      },
      "prognosis": {
        "games": "Spiele",
        "lost": "Verloren",
        "ranking": "Rang",
        "won": "Gewonnen"
      },
      "referee": "Schiri",
      "score": "Score",
      "setscores": "Ergebnisse pro Satz",
      "title": "Spiel"
    },
    "notifications": {
      "CaptainAssignedCounter": {
        "description": "{time} - {teams}",
        "title": "Zählen am {date}"
      },
      "CaptainAssignedReferee": {
        "description": "{time} - {teams}",
        "title": "Pfeifen am {date}"
      },
      "CaptainAvailability": {
        "description": "{time} - {teams}",
        "title": "Anwesenheit am {date}?"
      },
      "CaptainTasksAvailable": {
        "description": "{count} Wettkämpfe wurden {team} zugewiesen",
        "title": "Neue Aufgaben"
      },
      "MatchChange": {
        "description": "{datetime} - {teams}",
        "title": "Wettkampf wurde verschoben - Bist du trotzdem dabei?"
      },
      "markAllRead": "Alles als gelesen markieren",
      "title": "Benachrichtigung"
    },
    "pardoen": {
      "competition": "Offizieller",
      "description": "Es ist Donnerstagabend, das bedeutet nur eins: gezelligheid in Pardoen!",
      "me": "Mich",
      "seemore": "Barkartenstand",
      "summer": "Sommer",
      "title": "Pardoen - Barkarten"
    },
    "positions": {
      "diagonal": "Diagonal",
      "libero": "Libero",
      "middle": "Mittelblocker",
      "outside": "Außenangreifer",
      "setter": "Zuspieler"
    },
    "profile": {
      "about": "Über mich",
      "and": "und",
      "birthday": "Geburtstag",
      "birthday_lower": "Geburtstag",
      "captain": "Kapitän",
      "change": "ändern",
      "edit": "Profil bearbeiten",
      "email": "Email",
      "joined": "Mitglied seit",
      "map": "Karte",
      "of_honour": "Ehrenmitglied",
      "of_merit": "Verdienste Mitglied",
      "options": {
        "all": "Alle Mitglieder",
        "birthdays": "Geburtstage",
        "captains": "Kapitäne",
        "committee": "Komitee Mitglieder",
        "positions": "Positionen",
        "trainers": "Trainer"
      },
      "origin": "{name} kommt aus:",
      "phone": "Telefon",
      "relationcode": "Kennnummer",
      "retired": "Ehemaliges Mitglied",
      "stopped": "Gestoppt",
      "timeline": "Zeitleiste",
      "title": "Mitglieder",
      "trainingslid": "Trainingsmitglied",
      "type_to_search": "Tippe um zu suchen...",
      "unattached": "Nicht zugeordnet",
      "verenigingslid": "Vereinsmitglied",
      "view": "Profil ansehen",
      "with": "mit",
      "years": "Jahre"
    },
    "save": {
      "error": "Uups! Irgendwas ist schief gelaufen :(",
      "processing": "Speichern...",
      "success": "Gespeichert!"
    },
    "settings": {
      "calendar": {
        "description": "Um die Dinge leichter zugänglich zu machen, kannst du die Ereignisse in deinem Kalender abonnieren. Alles, was du auf \"Meine Seite\" siehst, wird auch in deiner Kalender-App angezeigt.",
        "disclaimer": "Beachte bitte, dass Kalender Apps keinen Standardumgang für Kalenderabonnements haben. Falls keine der Optionen funktioniert, kontaktiere:",
        "not_available": "Diese Funktion wird im Moment für deinen Account freigeschaltet. Probiere es später noch ein Mal.",
        "title": "Kalenderabonnements"
      },
      "cancel": {
        "text": "If you want to leave Erasmus Volley, please send an email to '<a href=\"mailto:secretaris@erasmusvolley.nl,tc@erasmusvolley.nl?subject=Cancel membership\">'secretaris@erasmusvolley.nl AND tc@erasmusvolley.nl'</a>.",
        "title": "Cancel membership"
      },
      "dashboard": "Dashboard",
      "origin": {
        "info": "Beispiel: Die Stadt, in der du aufgewachsen bist",
        "menu": "Ursprung",
        "placeholder": "Gib eine Stadt ein",
        "title": "Woher kommst du?"
      },
      "personal": {
        "address": {
          "city": "Residenz/Stadt",
          "postalcode": "Postleitzahl",
          "street": "Straße + Hausnr.",
          "title": "Adresse"
        },
        "contact": {
          "email": "E-Mail Adresse",
          "language": "Sprache",
          "phone": "Handynummer",
          "title": "Kontakt"
        },
        "name": {
          "firstname": "Vorname",
          "infix": "Infix",
          "lastname": "Nachname",
          "title": "Name"
        },
        "title": "Persönlich"
      },
      "picture": {
        "no_picture": "Du hast noch kein Foto hochgeladen.",
        "remove": "Entfernen",
        "title": "Foto",
        "upload": "Foto hochladen",
        "upload_new": "Neu",
        "uploading": "Einen Moment... dein Foto wird hochgeladen."
      },
      "privacy": {
        "birthday": "Geburtstag",
        "birthyear": "Geburtsjahr",
        "description": "Deine Privatsphäre ist uns wichtig und wir möchten dir die Kontrolle über deine persönlichen Daten geben. Wir versichern dir hiermit, dass neben Erasmus Volley Mitgliedern keine Dritte deine persönlichen Angaben zu sehen bekommen. Gebe hier an, welche persönlichen Angaben auf deinem Profil zu finden sind.",
        "email": "E-Mail",
        "nevobo": "Nevobo nummer",
        "phone": "Telefon",
        "revert": "Standardeinstellungen wiederherstellen",
        "title": "Privatsphäre"
      },
      "save": "Speichern",
      "saved": "Gespeichert",
      "security": {
        "confirm": "Neues Passwort (nochmal)",
        "current": "Aktuelles Passwort",
        "errors": {
          "current_password": {
            "incorrect": "Das aktuelle Passwort ist falsch.",
            "required": "Das aktuelle Passwort ist erforderlich."
          },
          "new_password": {
            "confirmed": "Das neue Passwort stimmt nicht mit der Bestätigung überein.",
            "min": "Das neue Passwort muss mindestens 6 Zeichen lang sein.",
            "required": "Das neue Passwort muss ausgefüllt sein."
          }
        },
        "new": "Neues Passwort",
        "title": "Sicherheit"
      },
      "social": {
        "facebook_info": "https://facebook.com/<strong>[benutzername]</strong>",
        "just_username": "Nur dein Benutzername",
        "title": "Social media",
        "username": "[benutzername]"
      },
      "title": "Einstellungen",
      "trainer": {
        "change": "Ändern",
        "date": "Datum",
        "fill": "Ausfüllen",
        "no_preference": "Keine Präferenz",
        "notes": "Kommentare",
        "save": "Speichern",
        "teams": {
          "high": "Hoch",
          "low": "Niedrig",
          "title": "Teams"
        },
        "times": {
          "early": "19:00 - 21:00",
          "late": "21:00 - 23:00",
          "not_filled_in": "Noch nicht ausgefüllt",
          "title": "Zeiten",
          "unavailable": "Nicht verfügbar"
        },
        "title": "Trainer"
      }
    },
    "sponsor": {
      "choose": {
        "description": "One of the webshops or restaurants",
        "title": "Choose"
      },
      "description": "Want to buy clothes? Book a holiday? Or just order food? With SponsorKliks you can sponsor Erasmus Volley through more than 300 stores and 4.500 restaurants, without paying a single dime more. ",
      "done": {
        "description": "You have now sponsored Erasmus Volley for free",
        "title": "Done"
      },
      "moreinfo": "More info",
      "order": {
        "description": "Like you are used to",
        "title": "Order"
      },
      "shopnow": "SHOP NOW",
      "short": "Sponsor EV for free",
      "title": "Sponsor Erasmus Volley for free",
      "winwin": "Win-Win!"
    },
    "standings": {
      "barcards": {
        "committee": "Komitee",
        "committees": "Barkartenstand des Komitees",
        "datetime": "Datum und Uhrzeit",
        "method": "Methode",
        "methods": {
          "bank": "Bank",
          "bank-qr": "Bank (mit QR)",
          "budget": "Budget",
          "cash": "Cash",
          "sumup": "Karte",
          "tikkie": "Tikkie",
          "unknown": ""
        },
        "more_committees": "mehr Komitees +",
        "more_members": "Top 10 +",
        "more_purchases": "mehr Einkäufe +",
        "more_teams": "mehr Teams +",
        "my_purchases": "Meine Einkäufe",
        "name": "Name",
        "pardoen_moved": "Ab jetzt kannst du hier den Barkartenstand finden!",
        "team": "Team",
        "teams": "Barkartenstand der Teams",
        "ten_members": "Top 10 Alkoholiker",
        "three_members": "Top 3 Alkoholiker",
        "total_sales": "In dieser Saison verkauft",
        "total_sales_summer": "In dieser Sommer verkauft"
      },
      "games": {
        "letter": "S",
        "word": "Spiele gespielt"
      },
      "max_points": {
        "letter": "M",
        "word": "Max. Punkte"
      },
      "percentage": "Prozentsatz",
      "points": {
        "letter": "P",
        "word": "Punkte"
      },
      "points_against": {
        "letter": "P-",
        "word": "Punkte verloren"
      },
      "points_for": {
        "letter": "P+",
        "word": "Punkte gewonnen"
      },
      "rank": {
        "letter": "#",
        "word": "Rang",
        "word_group": "Rang in der Gruppe"
      },
      "sets": {
        "letter": "S +/-",
        "word": "Sets +/-"
      },
      "sets_against": {
        "letter": "S-",
        "word": "Sets verloren"
      },
      "sets_for": {
        "letter": "S+",
        "word": "Sets gewonnen"
      },
      "team": "Team",
      "ten_best": "Die zehn besten Teamleistungen.",
      "title": "Stand und Ergebnisse"
    },
    "team": {
      "captain": "Kapitän",
      "favourites": "Meine Teams",
      "manager": {
        "attendance": {
          "description": "Wenn ein Teammitglied seine Verfügbarkeit noch nicht gemeldet hat, kannst du sie/ihn dazu auffordern indem du auf das Glockensymbol klickst. Du kannst das ein Mal pro Woche tun.",
          "title": "Präsenz (Anwesenheit)",
          "tooltip": "Verfügbarkeit anfragen"
        },
        "error_no_captain": "Du musst ein Kapitän sein, um das Team zu leiten.",
        "players": {
          "name": "Name",
          "positions": "Positionen",
          "title": "Spieler"
        },
        "tasks": {
          "assign": {
            "counter": "Zähler zuweisen",
            "counter2": "Zweite Zähler zuweisen",
            "referee": "Schiedsrichter zuweisen"
          },
          "by_matchsecretary": "von Leiter Spielbetrieb",
          "change": {
            "counter": "Zähler ändern",
            "counter2": "Zweite Zähler ändern",
            "referee": "Schiedsrichter ändern"
          },
          "involvement": {
            "available": "Verfügbar trotz Ausnahme",
            "exempt": "Freigestellt",
            "exemption": "Ausnahme",
            "exemption_text": "Wenn ein Mitglied das Maximum von 4 Sternchen erreicht hat, ist es von Aufgaben befreit und kann somit nicht mehr für diese eingetragen werden. Möchte dieses Mitglied jedoch trotzdem Aufgaben übernehmen, kann es sich beim Leiter Spielbetrieb melden. Einmalige Beauftragung (in Absprache) ist möglich nach dem Kontakt mit dem Leiter Spielbetrieb.",
            "howitworks": "Wie wird das Engagement beurteilt?",
            "intro": "Wenn Mitglieder ein Sternchen hinter ihrem Namen stehen haben, bedeutet das, dass sie Trainer sind oder eine Rolle in einem Komitee spielen. Natürlich sind nicht alle Komitees gleichwertig was das Pensum an Arbeit betrifft. Dies und die Häufigkeit der Trainings werden in der Anzahl der Sternchen angegeben.",
            "involvement": "Engagement",
            "matchsec_block": "Durch den Leiter Spielbetrieb gesperrt",
            "matchsec_block_text": "Der Leiter Spielbetrieb hat die Fähigkeit Mitglieder für Aufgaben auszuwählen und diese zu fixieren. In diesen Fällen können auch die Mannschaftskapitäne keine Änderungen vornehmen.",
            "retired": "Gestoppt"
          },
          "licence": "This match requires at least a '<strong>'{name}'</strong>' licence. Only players satisfying this requirement are shown.",
          "overview": "Übersicht",
          "task": "Aufgabe",
          "title": "Aufgaben",
          "unassigned": "nicht zugewiesen"
        },
        "title": "Manager"
      },
      "members_not_public": "Das technische Komitee hat die Teameinteilung noch nicht veröffentlicht.",
      "no_events_overview": "Es stehen ein den nächsten zwei Wochen keine Ereignisse für {team} an.",
      "no_events_schedule": "Es gibt keine geplanten Ereignisse für {team}.",
      "overview": "Übersicht",
      "players": "Spieler",
      "results": "Stand und Ergebnisse",
      "schedule": "Programm",
      "schedule_filters": {
        "away": "Auswärtsspiele",
        "home": "Heimspiele",
        "officials": "Officials",
        "training": "Training"
      },
      "subscribe": "Abonnieren",
      "subscribed": "Abonniert",
      "title": "Teams",
      "type": "{type, select, h { Men's } d { Ladies } g { Mixed } hr { Men's Recreational } dr { Ladies Recreational } other { Other }}",
      "types": {
        "d": "Damen",
        "dr": "Damen Recreational",
        "g": "Gemischt",
        "h": "Herren",
        "hr": "Herren Recreational",
        "other": "Andere"
      }
    },
    "tips": {
      "photo": "Lade über die Einstellungen ein Foto hoch um dein Profil aufzuhübschen!"
    },
    "trainings": {
      "availability": {
        "title": "Verfügbarkeit"
      },
      "courts": "Felder",
      "title": "Trainings",
      "title_s": "Training",
      "trainers": "Trainer"
    }
  }], ["en", {
    "auth": {
      "alreadyregistered": "Do you already have an account? Sign in!",
      "backtologin": "Back to login",
      "enter_email": "Enter your email",
      "error": {
        "already_registered": "You have already registered",
        "email": {
          "email": "Please enter a valid email address.",
          "required": "The email field is required."
        },
        "identification": {
          "email": "Please enter a valid email address.",
          "required": "The email field is required."
        },
        "invalid_credentials": "Incorrect password",
        "not_registered": "You have not registered for Play yet.",
        "password": {
          "required": "The password field is required."
        },
        "password_length": "The password must be at least 6 characters",
        "passwords_no_match": "Password confirmation doesn't match",
        "retired": "Sorry, it seems you are not a member of Erasmus Volley anymore.",
        "successful_forgot": "Almost done! Please check your email for a verification link.",
        "successful_registration": "Almost done! Please check your email for a verification link.",
        "too_many_attempts": "Too many login attempts. Please try again in {seconds} seconds.",
        "unknown": "Unknown error occurred.",
        "unrecognised_email": "Sorry, we don't recognise this email address."
      },
      "features": ["Homepage tailored to you", "More details about players", "Team manager for captains", "Upcoming birthdays", "Pardoen barcard standings"],
      "firsttime": "First time here?",
      "firsttimelong": "First time here? Sign up now!",
      "forgot": {
        "emailtitle": "Forgot email address",
        "helps": ["Try the email address on which you receive the regular member emails", "Ask the secretary"],
        "passwordtitle": "Forgot password",
        "title": "Forgot email/password"
      },
      "forgotpassword": "Forgot password?",
      "hi": "Hi",
      "login": "Sign in",
      "logout": "Sign out",
      "member_required": "You must be a member of Erasmus Volley to log in",
      "next": "Next",
      "password": "Password",
      "password_confirmation": "Password again",
      "pleasewait": "Please wait...",
      "signup": {
        "helps": ["Enter the email address you used when joining Erasmus Volley", "You must be a member of Erasmus Volley to sign up"],
        "submit": "Verify",
        "title": "Register"
      },
      "verify": {
        "error_detail": "It looks like this link has expired.",
        "error_title": "Oops! Something went wrong :(",
        "message": "Thanks for signing up for Erasmus Volley Play! All that's left is setting your password and you're ready to go.",
        "submit": "Finish",
        "title": "Hey {name}"
      },
      "welcome_back": "Welcome back",
      "welcomeback": "Welcome back!"
    },
    "calendar": {
      "title": "Calendar"
    },
    "committee": {
      "jobs": {
        "matchsecretary": "Match secretary",
        "penningmeester": "Treasurer",
        "president": "President",
        "promotions": "Promotions",
        "secretaris": "Secretary",
        "secretary": "Secretary",
        "treasurer": "Treasurer",
        "vicepresident": "Vice president",
        "vicevoorzitter": "Vice president",
        "voorzitter": "President",
        "wedstrijdsecretaris": "Match secretary"
      },
      "title": "Committees"
    },
    "court": {
      "TZ": "Traininghall",
      "court": "Court",
      "halls_long": {
        "1": "Hall 1 (ground floor)",
        "2": "Hall 2 (3rd floor)",
        "3": "Hall 3 (4th floor)"
      },
      "halls_short": {
        "1": "Hall 1",
        "2": "Hall 2",
        "3": "Hall 3"
      }
    },
    "dashboard": {
      "birthday_fundraiser": {
        "disclaimer": "Donations will be spent in Pardoen",
        "donate": "Donate via Tikkie"
      },
      "birthdays": {
        "none": "No birthdays today",
        "title": "Upcoming birthdays",
        "tomorrow": "Tomorrow"
      },
      "birthdays_tab": "Birthdays",
      "no_events": {
        "date": "Nothing is happening today.",
        "detail": "There are no upcoming events in the next week for your favourite teams.",
        "title": "Enjoy your time off!",
        "week": "Nothing is happening this week."
      },
      "thisweek_tab": "This week",
      "title": "My page",
      "upcoming": {
        "loading": "Loading events",
        "loading_with_date": "Loading events for {date}",
        "loading_with_week": "Loading events for week {week}",
        "week": "Week"
      }
    },
    "documents": {
      "title": "Documents"
    },
    "hall": {
      "address": "Address",
      "no_matches": "No more matches will be played in this hall this season.",
      "results": "Results",
      "schedule": "Schedule",
      "title": "Locations"
    },
    "locale": {
      "english": "English",
      "native": "English"
    },
    "match": {
      "attendance": {
        "cannot": "Can't go",
        "going": "Going",
        "interested": "Interested",
        "maybe": "Maybe",
        "noresponse": "No response",
        "remove": "Remove from list"
      },
      "away": "Away",
      "counter": "Counter",
      "court_unknown": "Court still unknown",
      "date": "Date",
      "datetime": "Date and time",
      "details": "Details",
      "directions": "Directions",
      "home": "Home",
      "last_results": "Ten most recent results",
      "location": "Location",
      "more_matches": "More matches",
      "official_actions": {
        "count": "Count at",
        "line": "Line judge at",
        "referee": "Referee at"
      },
      "prognosis": {
        "games": "Games",
        "lost": "Lost",
        "ranking": "Rank",
        "won": "Won"
      },
      "referee": "Referee",
      "score": "Score",
      "setscores": "Set scores",
      "title": "Match"
    },
    "notifications": {
      "CaptainAssignedCounter": {
        "description": "{time} - {teams}",
        "title": "Count on {date}"
      },
      "CaptainAssignedReferee": {
        "description": "{time} - {teams}",
        "title": "Referee on {date}"
      },
      "CaptainAvailability": {
        "description": "{time} - {teams}",
        "title": "Presence on {date}?"
      },
      "CaptainTasksAvailable": {
        "description": "There are {count} matches assigned to {team}",
        "title": "New tasks"
      },
      "MatchChange": {
        "description": "{datetime} - {teams}",
        "title": "Match changed - Are you (still) coming?"
      },
      "markAllRead": "Mark all as read",
      "title": "Notifications"
    },
    "pardoen": {
      "competition": "Official",
      "description": "It's Thursday evening, that means only one thing: gezelligheid in Pardoen!",
      "me": "Me",
      "seemore": "View the standings",
      "summer": "Summer",
      "title": "Pardoen - Barcards"
    },
    "positions": {
      "diagonal": "Opposite hitter",
      "libero": "Libero",
      "middle": "Middle",
      "outside": "Outside hitter",
      "setter": "Setter"
    },
    "profile": {
      "about": "About me",
      "and": "and",
      "birthday": "Birthday",
      "birthday_lower": "birthday",
      "captain": "Captain",
      "change": "change",
      "edit": "edit profile",
      "email": "Email",
      "joined": "Joined",
      "map": "Map",
      "of_honour": "Member of Honour",
      "of_merit": "Member of Merit",
      "options": {
        "all": "All members",
        "birthdays": "Birthdays",
        "captains": "Captains",
        "committee": "Committee members",
        "positions": "Positions",
        "trainers": "Trainers"
      },
      "origin": "{name} is from:",
      "phone": "Phone",
      "relationcode": "relation number",
      "retired": "Retired",
      "stopped": "Retired",
      "timeline": "Timeline",
      "title": "Members",
      "trainingslid": "Training member",
      "type_to_search": "Type to search...",
      "unattached": "Unattached",
      "verenigingslid": "Association member",
      "view": "View profile",
      "with": "with",
      "years": "years"
    },
    "save": {
      "error": "Whoops, something went wrong :(",
      "processing": "Saving...",
      "success": "Successfully saved!"
    },
    "settings": {
      "calendar": {
        "description": "To make things more easily accessible, you can subscribe to the events in your calendar. Everything you see on \"My page\" will also be visible in your calendar app.",
        "disclaimer": "Please note that there is no industry standard for calendar apps to deal with subscribed calendars. If none of the options above work for you, please ask the following for further assistance:",
        "not_available": "This function is currently being turned on for your account. Please try again later.",
        "title": "Calendar"
      },
      "cancel": {
        "text": "If you want to leave Erasmus Volley, please send an email to '<a href=\"mailto:secretaris@erasmusvolley.nl,tc@erasmusvolley.nl?subject=Cancel membership\">'secretaris@erasmusvolley.nl AND tc@erasmusvolley.nl'</a>.",
        "title": "Cancel membership"
      },
      "dashboard": "Dashboard",
      "origin": {
        "info": "Example: the town where you grew up",
        "menu": "Origin",
        "placeholder": "Enter a city",
        "title": "Where are you from?"
      },
      "personal": {
        "address": {
          "city": "City",
          "postalcode": "Postal code",
          "street": "Street + house nr.",
          "title": "Address"
        },
        "contact": {
          "email": "Email",
          "language": "Correspondence language",
          "phone": "Phone number",
          "title": "Contact"
        },
        "name": {
          "firstname": "First name",
          "infix": "Infix",
          "lastname": "Last name",
          "title": "Name"
        },
        "title": "Personal"
      },
      "picture": {
        "no_picture": "You haven't uploaded a photo yet",
        "remove": "Remove",
        "title": "Photo",
        "upload": "Upload a photo",
        "upload_new": "Upload new",
        "uploading": "Please wait... Your photo is being uploaded."
      },
      "privacy": {
        "birthday": "Birthday",
        "birthyear": "Birth year",
        "description": "Your privacy is very important to us and we want to give you the most control over what data you show to your fellow members. We want to highlight that none of the data fields below are ever shown to non-members. Tick the boxes to allow the data to be shown on your profile.",
        "email": "Email",
        "nevobo": "Nevobo relation number",
        "phone": "Phone",
        "revert": "Revert to standard privacy settings",
        "title": "Privacy"
      },
      "save": "Save",
      "saved": "Saved",
      "security": {
        "confirm": "Confirm new password",
        "current": "Current password",
        "errors": {
          "current_password": {
            "incorrect": "The current password is incorrect.",
            "required": "The current password field is required."
          },
          "new_password": {
            "confirmed": "The new password confirmation does not match.",
            "min": "The new password must be at least 6 characters.",
            "required": "The new password field is required."
          }
        },
        "new": "New password",
        "title": "Security"
      },
      "social": {
        "facebook_info": "https://facebook.com/<strong>[username]</strong>",
        "just_username": "Just your username",
        "title": "Social media",
        "username": "[username]"
      },
      "title": "Settings",
      "trainer": {
        "change": "Change",
        "date": "Date",
        "fill": "Fill in",
        "no_preference": "No preference",
        "notes": "Comments",
        "save": "Save",
        "teams": {
          "high": "High",
          "low": "Low",
          "title": "Teams"
        },
        "times": {
          "early": "19:00 - 21:00",
          "late": "21:00 - 23:00",
          "not_filled_in": "Not filled in yet",
          "title": "Times",
          "unavailable": "Unavailable"
        },
        "title": "Trainer"
      }
    },
    "sponsor": {
      "choose": {
        "description": "One of the webshops or restaurants",
        "title": "Choose"
      },
      "description": "Want to buy clothes? Book a holiday? Or just order food? With SponsorKliks you can sponsor Erasmus Volley through more than 300 stores and 4.500 restaurants, without paying a single dime more. ",
      "done": {
        "description": "You have now sponsored Erasmus Volley for free",
        "title": "Done"
      },
      "moreinfo": "More info",
      "order": {
        "description": "Like you are used to",
        "title": "Order"
      },
      "shopnow": "SHOP NOW",
      "short": "Sponsor EV for free",
      "title": "Sponsor Erasmus Volley for free",
      "winwin": "Win-Win!"
    },
    "standings": {
      "barcards": {
        "committee": "Committee",
        "committees": "Pubcard standings for committees",
        "datetime": "Date and time",
        "method": "Method",
        "methods": {
          "bank": "Bank",
          "bank-qr": "Bank (w/ QR)",
          "budget": "Budget",
          "cash": "Cash",
          "sumup": "Card",
          "tikkie": "Tikkie",
          "unknown": ""
        },
        "more_committees": "more committees +",
        "more_members": "Top 10 +",
        "more_purchases": "more purchases +",
        "more_teams": "more teams +",
        "my_purchases": "My purchases",
        "name": "Name",
        "pardoen_moved": "You can now find the pubcard standings here!",
        "team": "Team",
        "teams": "Pubcard standings for teams",
        "ten_members": "Top 10 drunkards",
        "three_members": "Top 3 drunkards",
        "total_sales": "Sold this season",
        "total_sales_summer": "Sold this summer"
      },
      "games": {
        "letter": "G",
        "word": "Games played"
      },
      "max_points": {
        "letter": "M",
        "word": "Max. points"
      },
      "percentage": "Percentage",
      "points": {
        "letter": "P",
        "word": "Points"
      },
      "points_against": {
        "letter": "P-",
        "word": "Points against"
      },
      "points_for": {
        "letter": "P+",
        "word": "Points for"
      },
      "rank": {
        "letter": "#",
        "word": "Rank",
        "word_group": "Rank in league"
      },
      "sets": {
        "letter": "S",
        "word": "Sets +/-"
      },
      "sets_against": {
        "letter": "S-",
        "word": "Sets against"
      },
      "sets_for": {
        "letter": "S+",
        "word": "Sets for"
      },
      "team": "Team",
      "ten_best": "Top ten best performing teams",
      "title": "Standings and results"
    },
    "team": {
      "captain": "Captain",
      "favourites": "My teams",
      "manager": {
        "attendance": {
          "description": "If teammates haven't made their availability known yet, you can remind them using the bell-icon. This can be done once per 7 days.",
          "title": "Attendance",
          "tooltip": "Ask for attendance"
        },
        "error_no_captain": "You need to be captain to manage the team.",
        "players": {
          "name": "Name",
          "positions": "Positions",
          "title": "Players"
        },
        "tasks": {
          "assign": {
            "counter": "Assign counter",
            "counter2": "Assign second counter",
            "referee": "Assign referee"
          },
          "by_matchsecretary": "by match secretary",
          "change": {
            "counter": "Change counter",
            "counter2": "Change second counter",
            "referee": "Change referee"
          },
          "involvement": {
            "available": "Available despite exemption",
            "exempt": "Exempted",
            "exemption": "Exemption",
            "exemption_text": "Once a member reaches 4 stars, he/she is exempted from these tasks and cannot be selected anymore. Should this member have no problem remaining available for these tasks, please contact the match secretary. One-off assignment (in consultation) is possible through the match secretary.",
            "howitworks": "How does the involvement work?",
            "intro": "Some members are trainers or take part in a committee, these people can be recognised by the stars behind their name. Not every committee carries the same weight and the frequency of trainership affeect the number of stars someone receives.",
            "involvement": "Involvement",
            "matchsec_block": "Locked by the match secretary",
            "matchsec_block_text": "The match secretary has the ability to select a member for a task and lock this selection. Captains can not change this selection.",
            "retired": "Retired"
          },
          "licence": "This match requires at least a '<strong>'{name}'</strong>' licence. Only players satisfying this requirement are shown.",
          "overview": "Overview",
          "task": "Task",
          "title": "Tasks",
          "unassigned": "unassigned"
        },
        "title": "Manager"
      },
      "members_not_public": "The technical committee hasn't made the line up available yet.",
      "no_events_overview": "There are no upcoming events in the next two weeks for {team}.",
      "no_events_schedule": "There are no upcoming events for {team}.",
      "overview": "Overview",
      "players": "Players",
      "results": "Results",
      "schedule": "Schedule",
      "schedule_filters": {
        "away": "Away matches",
        "home": "Home matches",
        "officials": "Officials",
        "training": "Trainings"
      },
      "subscribe": "Subscribe",
      "subscribed": "Subscribed",
      "title": "Teams",
      "type": "{type, select, h { Men's } d { Ladies } g { Mixed } hr { Men's Recreational } dr { Ladies Recreational } other { Other }}",
      "types": {
        "d": "Ladies",
        "dr": "Ladies Recreational",
        "g": "Mixed",
        "h": "Men's",
        "hr": "Men's Recreational",
        "other": "Other"
      }
    },
    "tips": {
      "photo": "Upload a photo via your settings to flourish your profile."
    },
    "trainings": {
      "availability": {
        "title": "Availability"
      },
      "courts": "Courts",
      "title": "Practices",
      "title_s": "Practice",
      "trainers": "Trainers"
    }
  }], ["nl", {
    "auth": {
      "alreadyregistered": "Heb je al een account? Log nu in!",
      "backtologin": "Terug naar inloggen",
      "enter_email": "Emailadres",
      "error": {
        "already_registered": "Je bent al geregistreerd",
        "email": {
          "email": "Vul een geldig email adres in.",
          "required": "Het email veld is verplicht."
        },
        "identification": {
          "email": "Vul een geldig email adres in.",
          "required": "Het email veld is verplicht."
        },
        "invalid_credentials": "Onjuist wachtwoord",
        "not_registered": "Je bent nog niet geregistreerd",
        "password": {
          "required": "Het wachtwoord veld is verplicht."
        },
        "password_length": "Het wachtwoord moet minimaal 6 karakters zijn",
        "passwords_no_match": "Wachtwoord bevestiging komt niet overeen",
        "retired": "Sorry, het lijkt erop dat je geen lid meer bent van Erasmus Volley.",
        "successful_forgot": "Bijna klaar! Check je email voor een verificatie link.",
        "successful_registration": "Bijna klaar! Check je email voor een verificatie link.",
        "too_many_attempts": "Te veel mislukte loginpogingen. Probeer het over {seconds} seconden nogmaals.",
        "unknown": "Onbekende fout",
        "unrecognised_email": "Sorry, we herkennen dit e-mail adres niet."
      },
      "features": ["Op maat gemaakte homepage", "Meer details over spelers", "Team manager voor aanvoerders", "Aankomende verjaardagen", "Pardoen barkaartenstand"],
      "firsttime": "Eerste keer hier?",
      "firsttimelong": "Eerste keer hier? Meld je nu aan!",
      "forgot": {
        "emailtitle": "Emailadres vergeten",
        "helps": ["Probeer het emailadres op welke je de ledenmails ontvangt", "Vraag de secretaris"],
        "passwordtitle": "Wachtwoord vergeten",
        "title": "Email/wachtwoord vergeten"
      },
      "forgotpassword": "Wachtwoord vergeten?",
      "hi": "Hoi",
      "login": "Inloggen",
      "logout": "Uitloggen",
      "member_required": "Je moet lid zijn van Erasmus Volley om in te loggen",
      "next": "Volgende",
      "password": "Wachtwoord",
      "password_confirmation": "Wachtwoord nogmaals",
      "pleasewait": "Een momentje...",
      "signup": {
        "helps": ["Gebruik hetzelfde emailadres als toen je lid werd van Erasmus Volley", "Je moet lid zijn van Erasmus Volley om aan te melden"],
        "submit": "Verifieren",
        "title": "Aanmelden"
      },
      "verify": {
        "error_detail": "Het lijkt erop dat de link verlopen is.",
        "error_title": "Oeps! Er is iets mis gegaan :(",
        "message": "Dank je wel voor het aanmelden voor Erasmus Volley Play! Je hoeft nu alleen nog maar een wachtwoord in te stellen en dan ben je klaar.",
        "submit": "Afronden",
        "title": "Hoi {name}!"
      },
      "welcome_back": "Welkom terug",
      "welcomeback": "Welkom terug!"
    },
    "calendar": {
      "title": "Kalender"
    },
    "committee": {
      "jobs": {
        "matchsecretary": "Westrijdsecretaris",
        "penningmeester": "Penningmeester",
        "president": "Voorzitter",
        "promotions": "Promotie",
        "secretaris": "Secretaris",
        "secretary": "Secretaris",
        "treasurer": "Penningmeester",
        "vicepresident": "Vicevoorzitter",
        "vicevoorzitter": "Vicevoorzitter",
        "voorzitter": "Voorzitter",
        "wedstrijdsecretaris": "Westrijdsecretaris"
      },
      "title": "Commissies"
    },
    "court": {
      "TZ": "Trainingzaal",
      "court": "Veld",
      "halls_long": {
        "1": "Zaal 1 (begane grond)",
        "2": "Zaal 2 (3e etage)",
        "3": "Zaal 3 (4e etage)"
      },
      "halls_short": {
        "1": "Zaal 1",
        "2": "Zaal 2",
        "3": "Zaal 3"
      }
    },
    "dashboard": {
      "birthday_fundraiser": {
        "disclaimer": "Donaties worden uitgegeven in Pardoen",
        "donate": "Doneren via Tikkie"
      },
      "birthdays": {
        "none": "Geen jarigen vandaag",
        "title": "Aankomende verjaardagen",
        "tomorrow": "Morgen"
      },
      "birthdays_tab": "Verjaardagen",
      "no_events": {
        "date": "Geen activiteiten vandaag.",
        "detail": "Er zijn geen activiteiten gepland in de komende week voor je favoriete teams.",
        "title": "Geniet van je vrije tijd!",
        "week": "Geen activiteiten deze week."
      },
      "thisweek_tab": "Deze week",
      "title": "Mijn pagina",
      "upcoming": {
        "loading": "Activiteiten aan het laden",
        "loading_with_date": "Activiteiten aan het laden voor {date}",
        "loading_with_week": "Activiteiten aan het laden voor week {week}",
        "week": "Week"
      }
    },
    "documents": {
      "title": "Documenten"
    },
    "hall": {
      "address": "Adres",
      "no_matches": "Er worden geen wedstrijden meer gespeeld in deze sporthal dit seizoen.",
      "results": "Uitslagen",
      "schedule": "Programma",
      "title": "Sporthallen"
    },
    "locale": {
      "english": "Dutch",
      "native": "Nederlands"
    },
    "match": {
      "attendance": {
        "cannot": "Kan niet gaan",
        "going": "Gaat",
        "interested": "Geïnteresseerd",
        "maybe": "Misschien",
        "noresponse": "Geen antwoord",
        "remove": "Verwijderen van lijst"
      },
      "away": "Uit",
      "counter": "Teller",
      "court_unknown": "Veld nog niet bekend",
      "date": "Datum",
      "datetime": "Datum en tijd",
      "details": "Details",
      "directions": "Routebeschrijving",
      "home": "Thuis",
      "last_results": "Tien laatste uitslagen",
      "location": "Locatie",
      "more_matches": "Meer wedstrijden",
      "official_actions": {
        "count": "Tellen bij",
        "line": "Lijnrechter bij",
        "referee": "Fluiten bij"
      },
      "prognosis": {
        "games": "Wedstrijden",
        "lost": "Verloren",
        "ranking": "Rang",
        "won": "Gewonnen"
      },
      "referee": "Scheids",
      "score": "Score",
      "setscores": "Uitslagen per set",
      "title": "Wedstrijd"
    },
    "notifications": {
      "CaptainAssignedCounter": {
        "description": "{time} - {teams}",
        "title": "Tellen op {date}"
      },
      "CaptainAssignedReferee": {
        "description": "{time} - {teams}",
        "title": "Fluiten op {date}"
      },
      "CaptainAvailability": {
        "description": "{time} - {teams}",
        "title": "Aanwezigheid op {date}?"
      },
      "CaptainTasksAvailable": {
        "description": "Er zijn {count} wedstrijden aan {team} toegewezen",
        "title": "Nieuwe taken"
      },
      "MatchChange": {
        "description": "{datetime} - {teams}",
        "title": "Wedstrijdwijziging - Ben je er nu (ook) bij?"
      },
      "markAllRead": "Alles als gelezen markeren",
      "title": "Berichten"
    },
    "pardoen": {
      "competition": "Officiële",
      "description": "Het is weer donderdagavond en dat betekent maar één ding: gezelligheid in Pardoen!",
      "me": "Ik",
      "seemore": "Bekijk de stand",
      "summer": "Zomer",
      "title": "Pardoen - Barkaarten"
    },
    "positions": {
      "diagonal": "Diagonaal",
      "libero": "Libero",
      "middle": "Midden",
      "outside": "Passer-loper",
      "setter": "Spelverdeler"
    },
    "profile": {
      "about": "Over mij",
      "and": "en",
      "birthday": "Verjaardag",
      "birthday_lower": "verjaardag",
      "captain": "Aanvoerder",
      "change": "wijzigen",
      "edit": "profiel bewerken",
      "email": "Email",
      "joined": "Lid geworden",
      "map": "Kaart",
      "of_honour": "Erelid",
      "of_merit": "Lid van Verdienste",
      "options": {
        "all": "Alle leden",
        "birthdays": "Verjaardagen",
        "captains": "Aanvoerders",
        "committee": "Commissieleden",
        "positions": "Posities",
        "trainers": "Trainers"
      },
      "origin": "{name} komt uit:",
      "phone": "Telefoon",
      "relationcode": "relatiecode",
      "retired": "Oud-lid",
      "stopped": "Gestopt",
      "timeline": "Tijdlijn",
      "title": "Leden",
      "trainingslid": "Trainingslid",
      "type_to_search": "Typ om te zoeken...",
      "unattached": "Unattached",
      "verenigingslid": "Verenigingslid",
      "view": "Profiel bekijken",
      "with": "met",
      "years": "jaar"
    },
    "save": {
      "error": "Oops, er is iets fout gegaan :(",
      "processing": "Aan het opslaan...",
      "success": "Opgeslagen!"
    },
    "settings": {
      "calendar": {
        "description": "Om dingen nog toegankelijker te maken kan je abonneren op de evenementen in je agenda. Alles wat je op \"Mijn pagina\" ziet is dan ook zichtbaar in je agenda app.",
        "disclaimer": "Let wel dat er geen industriestandaard is voor apps om met geabonnereerde agenda's te werken. Mocht geen van de bovenstaande opties voor jou werken, neem contact op voor hulp met:",
        "not_available": "Deze functie wordt momenteel geactiveerd voor jouw account. Probeer het later nog eens.",
        "title": "Agenda"
      },
      "cancel": {
        "text": "Wanneer je je wilt uitschrijven bij Erasmus Volley, stuur dan een mail naar '<a href=\"mailto:secretaris@erasmusvolley.nl,tc@erasmusvolley.nl?subject=Lidmaatschap opzeggen\">'secretaris@erasmusvolley.nl EN tc@erasmusvolley.nl'</a>.",
        "title": "Lidmaatschap opzeggen"
      },
      "dashboard": "Dashboard",
      "origin": {
        "info": "Voorbeeld: waar je bent opgegroeid",
        "menu": "Oorsprong",
        "placeholder": "Vul een plaats in",
        "title": "Waar kom je vandaan?"
      },
      "personal": {
        "address": {
          "city": "Woonplaats",
          "postalcode": "Postcode",
          "street": "Straat + huisnr.",
          "title": "Adres"
        },
        "contact": {
          "email": "Email",
          "language": "Taal",
          "phone": "Mobiel",
          "title": "Correspondentie"
        },
        "name": {
          "firstname": "Voornaam",
          "infix": "Tussenvoegsel",
          "lastname": "Achternaam",
          "title": "Naam"
        },
        "title": "Persoonlijk"
      },
      "picture": {
        "no_picture": "Je hebt nog geen foto geupload",
        "remove": "Verwijderen",
        "title": "Foto",
        "upload": "Foto uploaden",
        "upload_new": "Nieuw",
        "uploading": "Één moment... Je foto wordt geüpload."
      },
      "privacy": {
        "birthday": "Verjaardag",
        "birthyear": "Geboortejaar",
        "description": "Je privacy is erg belangrijk voor ons en we willen jou de meeste controle geven over welke gegevens je aan je medeleden wilt laten zien. We willen benadrukken dat geen van de onderstaande gegevensvelden ooit wordt getoond aan niet-leden. Vink de vakjes aan om toe te staan dat de gegevens op je profiel worden getoond.",
        "email": "Email",
        "nevobo": "Nevobo relatiecode",
        "phone": "Telefoon",
        "revert": "Terugzetten naar standaard privacy instellingen",
        "title": "Privacy"
      },
      "save": "Opslaan",
      "saved": "Opgeslagen",
      "security": {
        "confirm": "Nieuw wachtwoord nogmaals",
        "current": "Huidig wachtwoord",
        "errors": {
          "current_password": {
            "incorrect": "Huidig wachtwoord is incorrect.",
            "required": "Huidig wachtwoord is verplicht."
          },
          "new_password": {
            "confirmed": "Nieuw wachtwoord bevestiging komt niet overeen.",
            "min": "Nieuw wachtwoord moet minimaal 6 karakters zijn.",
            "required": "Nieuw wachtwoord is verplicht."
          }
        },
        "new": "Nieuw wachtwoord",
        "title": "Beveiliging"
      },
      "social": {
        "facebook_info": "https://facebook.com/<strong>[gebruikersnaam]</strong>",
        "just_username": "Alleen je gebruikersnaam",
        "title": "Social media",
        "username": "[gebruikersnaam]"
      },
      "title": "Instellingen",
      "trainer": {
        "change": "Wijzigen",
        "date": "Datum",
        "fill": "Invullen",
        "no_preference": "Geen voorkeur",
        "notes": "Opmerkingen",
        "save": "Opslaan",
        "teams": {
          "high": "Hoog",
          "low": "Laag",
          "title": "Teams"
        },
        "times": {
          "early": "19:00 - 21:00",
          "late": "21:00 - 23:00",
          "not_filled_in": "Nog niet ingevuld",
          "title": "Tijden",
          "unavailable": "Onbeschikbaar"
        },
        "title": "Trainer"
      }
    },
    "sponsor": {
      "choose": {
        "description": "Één van de vele webwinkels of restaurants",
        "title": "Kies"
      },
      "description": "Kleding kopen? Vakantie boeken? Of gewoon eten bestellen? Via SponsorKliks kan je bij ruim 300 winkels en 4.500 restaurants Erasmus Volley sponsoren zonder daar een cent extra voor te betalen.",
      "done": {
        "description": "Je hebt nu gratis Erasmus Volley gesponsord",
        "title": "Klaar"
      },
      "moreinfo": "Meer info",
      "order": {
        "description": "Zoals je dat gewend bent",
        "title": "Bestel"
      },
      "shopnow": "NU SHOPPEN",
      "short": "Gratis EV sponsoren",
      "title": "Gratis Erasmus Volley sponsoren?!",
      "winwin": "Win-Win!"
    },
    "standings": {
      "barcards": {
        "committee": "Commissie",
        "committees": "Barkaartenstand voor commissies",
        "datetime": "Datum en tijd",
        "method": "Method",
        "methods": {
          "bank": "Bank",
          "bank-qr": "Bank (met QR)",
          "budget": "Begroting",
          "cash": "Cash",
          "sumup": "Kaart",
          "tikkie": "Tikkie",
          "unknown": ""
        },
        "more_committees": "meer commissies +",
        "more_members": "Top 10 +",
        "more_purchases": "meer aankopen +",
        "more_teams": "meer teams +",
        "my_purchases": "Mijn aankopen",
        "name": "Naam",
        "pardoen_moved": "Vanaf nu kan je hier de barkaartenstand vinden!",
        "team": "Team",
        "teams": "Barkaartenstand voor teams",
        "ten_members": "Top 10 zuiplappen",
        "three_members": "Top 3 zuiplappen",
        "total_sales": "Verkocht dit seizoen",
        "total_sales_summer": "Verkocht deze zomer"
      },
      "games": {
        "letter": "W",
        "word": "Wedstrijden gespeeld"
      },
      "max_points": {
        "letter": "M",
        "word": "Max. punten"
      },
      "percentage": "Percentage",
      "points": {
        "letter": "P",
        "word": "Punten"
      },
      "points_against": {
        "letter": "P-",
        "word": "Punten tegen"
      },
      "points_for": {
        "letter": "P+",
        "word": "Punten voor"
      },
      "rank": {
        "letter": "#",
        "word": "Plaats",
        "word_group": "Plaats in poule"
      },
      "sets": {
        "letter": "S +/-",
        "word": "Sets +/-"
      },
      "sets_against": {
        "letter": "S-",
        "word": "Sets tegen"
      },
      "sets_for": {
        "letter": "S+",
        "word": "Sets voor"
      },
      "team": "Team",
      "ten_best": "Top tien best presterende teams",
      "title": "Standen en uitslagen"
    },
    "team": {
      "captain": "Aanvoerder",
      "favourites": "Mijn teams",
      "manager": {
        "attendance": {
          "description": "Als teamgenoten hun beschikbaarheid nog niet hebben opgegeven, kan je hen hieraan herinneren met het bel-icoontje. Dit kan één keer per 7 dagen.",
          "title": "Aanwezigheid",
          "tooltip": "Vragen om aanwezigheid"
        },
        "error_no_captain": "Je moet aanvoerder zijn om het team te beheren.",
        "players": {
          "name": "Naam",
          "positions": "Posities",
          "title": "Spelers"
        },
        "tasks": {
          "assign": {
            "counter": "Teller toewijzen",
            "counter2": "Tweede teller toewijzen",
            "referee": "Scheids toewijzen"
          },
          "by_matchsecretary": "door wedstrijdsecretaris",
          "change": {
            "counter": "Teller wijzigen",
            "counter2": "Tweede teller wijzigen",
            "referee": "Scheids wijzigen"
          },
          "involvement": {
            "available": "Beschikbaar gesteld ondanks vrijstelling",
            "exempt": "Vrijgesteld",
            "exemption": "Vrijstelling",
            "exemption_text": "Als een lid 4 sterren heeft dan wordt hij/zij vrijgesteld van deze taken en kan dan niet meer geselecteerd worden. Mocht dit lid het geen probleem vinden om ook beschikbaar te blijven zijn voor deze taken, neem dan contact op met de wedstrijdsecretaris. Eenmalige toewijzing (in overleg) is ook mogelijk via de wedstrijdsecretaris.",
            "howitworks": "Hoe werkt de betrokkenheid?",
            "intro": "Sommige leden zijn trainers of zitten in een commissie, deze kun je herkennen aan de sterretjes achter de naam. Niet elke commissie telt even zwaar en de frequentie van het trainerschap heeft ook effect op het aantal sterren dat iemand krijgt.",
            "involvement": "Betrokkenheid",
            "matchsec_block": "Blokkade door westrijdsecretaris",
            "matchsec_block_text": "De wedstrijdsecretaris heeft de mogelijkheid een lid aan te wijzen voor een wedstrijd en deze selectie dan vast te zetten. Aanvoerders kunnen deze selectie niet wijzigen.",
            "retired": "Gestopt"
          },
          "licence": "Voor deze wedstrijd is minstens een '<strong>'{name}'</strong>' licentie vereist. Alleen spelers die hieraan voldoen worden getoond.",
          "overview": "Overzicht",
          "task": "Taak",
          "title": "Taken",
          "unassigned": "niet toegewezen"
        },
        "title": "Manager"
      },
      "members_not_public": "De technische commissie heeft de team indeling nog niet beschikbaar gemaakt.",
      "no_events_overview": "Er zijn geen activiteiten gepland voor {team} in de komende twee weken.",
      "no_events_schedule": "Er zijn geen activiteiten gepland voor {team}.",
      "overview": "Overzicht",
      "players": "Spelers",
      "results": "Stand en uitslagen",
      "schedule": "Programma",
      "schedule_filters": {
        "away": "Uitwedstrijden",
        "home": "Thuiswedstrijden",
        "officials": "Officials",
        "training": "Trainingen"
      },
      "subscribe": "Abonneren",
      "subscribed": "Geabonneerd",
      "title": "Teams",
      "type": "{type, select, h { Men's } d { Ladies } g { Mixed } hr { Men's Recreational } dr { Ladies Recreational } other { Other }}",
      "types": {
        "d": "Dames",
        "dr": "Dames Recreatief",
        "g": "Gemengd",
        "h": "Heren",
        "hr": "Heren Recreatief",
        "other": "Overig"
      }
    },
    "tips": {
      "photo": "Upload een foto via je instellingen om je profiel op te vrolijken."
    },
    "trainings": {
      "availability": {
        "title": "Beschikbaarheid"
      },
      "courts": "Velden",
      "title": "Trainingen",
      "title_s": "Training",
      "trainers": "Trainers"
    }
  }]];
  _exports.default = _default;
});