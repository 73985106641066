define("ember-pusher/mixins/client-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Fire an event programmatically. All Events must unfortunately use
    // the client-<eventname> format for client events (a pusher restriction).
    pusherTrigger: function pusherTrigger(channelName, eventName, data) {
      var channel = this.pusher.channelFor(channelName);
      channel.trigger(eventName, data);
    }
  });

  _exports.default = _default;
});