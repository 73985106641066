define("ember-pusher/initializers/ember-pusher-injections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    application.inject("controller", "pusher", "service:pusher");
    application.inject("route", "pusher", "service:pusher");
  }

  var _default = {
    name: "ember-pusher-injections",
    initialize: initialize
  };
  _exports.default = _default;
});