define('@fortawesome/pro-light-svg-icons', ['exports'], function (exports) { 'use strict';

  var faBriefcase = {
    prefix: 'fal',
    iconName: 'briefcase',
    icon: [512, 512, [], "f0b1", "M464 128H352V56c0-13.26-10.74-24-24-24H184c-13.26 0-24 10.74-24 24v72H48c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zM192 64h128v64H192V64zm288 368c0 8.82-7.18 16-16 16H48c-8.82 0-16-7.18-16-16V288h160v40c0 13.25 10.75 24 24 24h80c13.25 0 24-10.75 24-24v-40h160v144zM224 320v-32h64v32h-64zm256-64H32v-80c0-8.82 7.18-16 16-16h416c8.82 0 16 7.18 16 16v80z"]
  };
  var faCalendarMinus = {
    prefix: 'fal',
    iconName: 'calendar-minus',
    icon: [448, 512, [], "f272", "M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zm-92-128H140c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12z"]
  };
  var faInfoCircle = {
    prefix: 'fal',
    iconName: 'info-circle',
    icon: [512, 512, [], "f05a", "M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"]
  };
  var faStar = {
    prefix: 'fal',
    iconName: 'star',
    icon: [576, 512, [], "f005", "M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"]
  };
  var faUserGraduate = {
    prefix: 'fal',
    iconName: 'user-graduate',
    icon: [448, 512, [], "f501", "M319.4 320.6L224 400l-95.4-79.4C110.2 321.4 0 336.1 0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48 0-127.9-110.1-142.6-128.6-143.4zM208 480H48c-8.8 0-16-7.2-16-16 0-99.6 84.1-109.9 86.4-110.3l89.6 74.6V480zm208-16c0 8.8-7.2 16-16 16H240v-51.7l89.6-74.6c2.3.4 86.4 10.7 86.4 110.3zM13.2 100l6.8 2v37.6c-7 4.2-12 11.5-12 20.3 0 8.4 4.6 15.4 11.1 19.7L3.5 242c-1.7 6.9 2.1 14 7.6 14h41.8c5.5 0 9.3-7.1 7.6-14l-15.6-62.3C51.4 175.4 56 168.4 56 160c0-8.8-5-16.1-12-20.3v-30.5L90.7 123C84 139.4 80 157.2 80 176c0 79.5 64.5 144 144 144s144-64.5 144-144c0-18.8-4-36.6-10.7-53l77.5-23c17.6-5.2 17.6-34.8 0-40L240.9 2.5C235.3.8 229.7 0 224 0s-11.3.8-16.9 2.5L13.2 60c-17.6 5.2-17.6 34.8 0 40zM224 288c-61.8 0-112-50.2-112-112 0-15.7 3.7-30.3 9.6-43.8l85.5 25.4c14.8 4.4 27.2 2 33.8 0l85.5-25.4c5.9 13.5 9.6 28.2 9.6 43.8 0 61.8-50.2 112-112 112zm-7.8-254.9c.8-.2 7.3-2.4 15.6 0l158 46.9-158 46.9c-.8.2-7.3 2.4-15.6 0L58.2 80l158-46.9z"]
  };

  exports.faBriefcase = faBriefcase;
  exports.faCalendarMinus = faCalendarMinus;
  exports.faInfoCircle = faInfoCircle;
  exports.faStar = faStar;
  exports.faUserGraduate = faUserGraduate;

  Object.defineProperty(exports, '__esModule', { value: true });

});
