define('ember-place-autocomplete/components/place-autocomplete-field', ['exports', 'ember-place-autocomplete/templates/components/place-autocomplete-field'], function (exports, _placeAutocompleteField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      typeOf = Ember.typeOf,
      isEqual = Ember.isEqual,
      run = Ember.run;
  exports.default = Component.extend({
    layout: _placeAutocompleteField.default,
    disabled: false,
    inputClass: 'place-autocomplete--input',
    types: 'geocode',
    restrictions: {},
    tabindex: 0,
    withGeoLocate: false,
    setValueWithProperty: 'formatted_address',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this, 'setupComponent');
    },


    // Wait until the google library is loaded by calling this method
    // every 100ms
    setupComponent: function setupComponent() {
      var _this = this;

      if (document && window && window.google && window.google.maps) {
        this.setAutocomplete();
        if (this.get('withGeoLocate')) {
          this.geolocateAndSetBounds();
        }
        this.get('autocomplete').addListener('place_changed', function () {
          run(function () {
            _this.placeChanged();
          });
        });
      } else {
        if (!this.isDestroyed) {
          run.later(this, 'setupComponent', 100);
        }
      }
    },
    willDestroy: function willDestroy() {
      if (isPresent(this.get('autocomplete'))) {
        var google = this.get('google') || (window ? window.google : null);
        if (google && google.maps && google.maps.event) {
          google.maps.event.clearInstanceListeners(this.get('autocomplete'));
        }
      }
    },
    setAutocomplete: function setAutocomplete() {
      if (isEmpty(this.get('autocomplete'))) {
        var inputElement = document.getElementById(this.elementId).getElementsByTagName('input')[0],
            google = this.get('google') || window.google,
            //TODO: check how to use the inyected google object
        options = { types: this._typesToArray() };
        if (this.get('latLngBounds')) {
          // @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
          var _get = this.get('latLngBounds'),
              sw = _get.sw,
              ne = _get.ne;

          options.bounds = new google.maps.LatLngBounds(sw, ne);
        }
        if (Object.keys(this.get('restrictions')).length > 0) {
          options.componentRestrictions = this.get('restrictions');
        }
        var autocomplete = new google.maps.places.Autocomplete(inputElement, options);
        this.set('autocomplete', autocomplete);
      }
    },


    // @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
    geolocateAndSetBounds: function geolocateAndSetBounds() {
      var _this2 = this;

      var navigator = this.get('navigator') || (window ? window.navigator : null);
      var autocomplete = this.get('autocomplete');
      if (navigator && navigator.geolocation && isPresent(autocomplete)) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var google = _this2.get('google') || window.google;
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          autocomplete.setBounds(circle.getBounds());
        });
      }
    },
    placeChanged: function placeChanged() {
      var place = this.get('autocomplete').getPlace();
      this._callCallback('placeChangedCallback', place);

      if (place[this.get('setValueWithProperty')] !== undefined) {
        this.set('value', place[this.get('setValueWithProperty')]);
      } else {
        // Address not found use value
        this.set('value', place.name);
      }
    },
    _callCallback: function _callCallback(callback, place) {
      var callbackFn = this.get(callback);
      if (isEqual(typeOf(callbackFn), 'function')) {
        callbackFn(place);
      } else {
        var actionName = this.get(callback);
        if (isPresent(this.get('handlerController')) && isPresent(actionName)) {
          this.get('handlerController').send(actionName, place);
        }
      }
    },
    _typesToArray: function _typesToArray() {
      if (this.get('types') !== '') {
        return this.get('types').split(',');
      } else {
        return [];
      }
    },


    actions: {
      focusOut: function focusOut() {
        this._callCallback('focusOutCallback');
      }
    }
  });
});