define("ember-pusher/mixins/bindings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      var _this = this;

      if (this.PUSHER_SUBSCRIPTIONS) {
        Ember.keys(this.PUSHER_SUBSCRIPTIONS).forEach(function (channelName) {
          var events = _this.PUSHER_SUBSCRIPTIONS[channelName];

          _this.pusher.wire(_this, channelName, events);
        });
      }

      return this._super.apply(this, arguments);
    },
    willDestroy: function willDestroy() {
      var _this2 = this;

      if (this.PUSHER_SUBSCRIPTIONS) {
        Ember.keys(this.PUSHER_SUBSCRIPTIONS).forEach(function (channelName) {
          _this2.pusher.unwire(_this2, channelName);
        });
      }

      return this._super.apply(this, arguments);
    },
    _pusherEventsId: function _pusherEventsId() {
      return this.toString();
    }
  });

  _exports.default = _default;
});