define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MXr5HqC8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[32,0,[\"fluid\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[30,[36,0],[[32,0,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[32,0,[\"toggleNavbar\"]],[32,0,[\"_collapsed\"]]]]],[30,[36,0],[[32,0,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[32,0,[\"_collapsed\"]],[32,0,[\"onCollapsed\"]],[32,0,[\"onExpanded\"]]]]],[30,[36,0],[[32,0,[\"navComponent\"]]],[[\"linkToComponent\"],[[30,[36,0],[[32,0,[\"linkToComponent\"]]],[[\"onCollapse\"],[[32,0,[\"collapse\"]]]]]]]],[32,0,[\"collapse\"]],[32,0,[\"expand\"]],[32,0,[\"toggleNavbar\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[30,[36,0],[[32,0,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[32,0,[\"toggleNavbar\"]],[32,0,[\"_collapsed\"]]]]],[30,[36,0],[[32,0,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[32,0,[\"_collapsed\"]],[32,0,[\"onCollapsed\"]],[32,0,[\"onExpanded\"]]]]],[30,[36,0],[[32,0,[\"navComponent\"]]],[[\"linkToComponent\"],[[30,[36,0],[[32,0,[\"linkToComponent\"]]],[[\"onCollapse\"],[[32,0,[\"collapse\"]]]]]]]],[32,0,[\"collapse\"]],[32,0,[\"expand\"]],[32,0,[\"toggleNavbar\"]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});