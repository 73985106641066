define("ember-bootstrap/templates/components/bs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6KXzufey",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[15,0,[32,0,[\"icon\"]]],[12],[13],[2,\" \"]],\"parameters\":[]}]]],[1,[32,0,[\"text\"]]],[18,1,[[30,[36,1],null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[32,0,[\"isFulfilled\"]],[32,0,[\"isPending\"]],[32,0,[\"isRejected\"]],[32,0,[\"isSettled\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-button.hbs"
    }
  });

  _exports.default = _default;
});